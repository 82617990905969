
import api from '@/api'
import { UnavailableDaysDto } from 'service/__generated-api'
import { defineComponent, onMounted, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    unavailableDays: {
      type: Object as PropType<UnavailableDaysDto>
    }
  },
  setup() {
    const unavailableDays = ref<UnavailableDaysDto>()

    onMounted(async () => {
      try {
        const { data } = await api.days.getUnavailableDays()
        unavailableDays.value = data
      } catch (error) {
        unavailableDays.value = undefined
      }
    })

    const unpaidLeaveTooltipText =
      'Unpaid leave that you can take after exceeding the 30-day limit'

    const headers = {
      acceptedPaid: { header: 'Accepted Paid Leave', tooltip: '' },
      plannedPaid: { header: 'Planned Paid Leave', tooltip: '' },
      acceptedUnpaid: {
        header: 'Accepted Unpaid Leave',
        tooltip: unpaidLeaveTooltipText
      },
      plannedUnpaid: {
        header: 'Planned Unpaid Leave',
        tooltip: unpaidLeaveTooltipText
      }
    }

    return { headers }
  }
})
