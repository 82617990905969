
import SelectDayTemplate from '@/components/select-day-template.vue'
import DayTemplateForm from '@/components/day-template-form.vue'
import { defineComponent, PropType, ref, watch } from 'vue'
import { MyWorkTab } from '@/interfaces'
import { DayDto } from 'service/__generated-api'
import { parse as parseDate } from 'date-fns'
import { notAvailableArgs } from '@/utils'
import { useStore } from 'vuex'

export default defineComponent({
  components: { SelectDayTemplate, DayTemplateForm },
  data() {
    return {
      timesInvalidity: false
    }
  },
  props: {
    activeTab: {
      required: true,
      type: String as PropType<MyWorkTab>
    },
    selectedDay: {
      required: true,
      type: Object as PropType<DayDto>
    },
    selectedDays: {
      required: true,
      type: Array as PropType<string[]>
    }
  },
  computed: {
    isUserPartTimeEmployee() {
      return useStore().state.user.worksPartTime
    },
    notAvailableIndicators(): notAvailableArgs {
      return {
        comment: this.comment,
        halfNotAvailable: this.halfNotAvailable,
        atConference: this.atConference,
        unpaid: this.unpaid,
        occasionalAbsence: this.occasionalAbsence,
        notAvailable: this.notAvailable,
        halfNotAvailableStartTime: this.halfNotAvailableStartTime,
        halfNotAvailableEndTime: this.halfNotAvailableEndTime,
        markedByPartTimeUser: this.markedByPartTimeUser
      }
    },
    commentPlaceholderText(): string {
      if (this.occasionalAbsence) {
        return 'e.g. Odebranie nadgodzin 🕑'
      }
      if (this.unpaid) {
        return 'e.g. Nieplanowany wyjazd ❌'
      }
      if (this.atConference) {
        return 'e.g. Konferencja 🎓'
      }
      return 'e.g. Urlop / Odrobienie nieobecności ❌'
    },
    haveToFillTimeWhenHalfAbsence(): boolean {
      return (
        this.halfNotAvailable &&
        !this.halfNotAvailableStartTime &&
        !this.halfNotAvailableEndTime
      )
    },
    selectedAbsenceType() {
      if (this.unpaid) {
        return 'unpaid'
      } else if (this.occasionalAbsence) {
        return 'occasionalAbsence'
      } else if (this.atConference) {
        return 'atConference'
      }
      return 'notAvailable'
    },
    absenceOptions() {
      return [
        { key: 'notAvailable', name: 'Absent' },
        { key: 'unpaid', name: 'Unpaid absence' },
        { key: 'occasionalAbsence', name: 'Special absence' },
        { key: 'atConference', name: 'Course/Conference' }
      ]
    },
    finishMarkingUnavailabilityText(): string {
      if (this.occasionalAbsence) return 'Special absence'
      if (this.atConference) return 'Going on course or conference'
      if (this.unpaid) return 'Unpaid absence'
      return 'Not available'
    }
  },
  methods: {
    checkTimesAreValidAndSet4hStep() {
      const formatRegex = /^\d\d:\d\d$/
      if (!formatRegex.test(this.halfNotAvailableStartTime))
        this.timesInvalidity = true
      else
        this.timesInvalidity = !formatRegex.test(this.halfNotAvailableEndTime)
      if (!this.timesInvalidity) this.set4hTimeStep()
    },
    set4hTimeStep() {
      const fromTime = parseDate(
        this.halfNotAvailableStartTime,
        'HH:mm',
        new Date()
      )
      const limitedToTimeByMax4hStepPerDay = parseDate(
        '20:00',
        'HH:mm',
        new Date()
      )
      if (!fromTime.getTime() || fromTime > limitedToTimeByMax4hStepPerDay) {
        this.timesInvalidity = true
      } else {
        const dateToStr = (date: Date) => {
          return date.toTimeString().split(' ')[0].slice(0, -3)
        }
        const addHours = (date: Date, hours: number) => {
          date.setHours(date.getHours() + hours)
          return date
        }
        const newEndTimeWithAdded4h = addHours(fromTime, 4)
        this.halfNotAvailableEndTime = dateToStr(newEndTimeWithAdded4h)
      }
    },
    setDefaultTime() {
      this.halfNotAvailableStartTime = this.halfNotAvailableStartTime
        ? ''
        : '10:00'
      this.halfNotAvailableEndTime = this.halfNotAvailableEndTime ? '' : '14:00'
    },
    adjustChecksDueToPartTimeJob() {
      if (this.markedByPartTimeUser) {
        this.halfNotAvailable = false
        this.halfNotAvailableStartTime = ''
        this.halfNotAvailableEndTime = ''
      }
    },
    onAbsenceTypeChange(event: string) {
      if (event == 'unpaid') {
        this.unpaid = true
        this.occasionalAbsence = false
        this.atConference = false
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else if (event == 'occasionalAbsence') {
        this.unpaid = false
        this.occasionalAbsence = true
        this.atConference = false
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else if (event == 'atConference') {
        this.unpaid = false
        this.occasionalAbsence = false
        this.atConference = true
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else {
        this.unpaid = false
        this.occasionalAbsence = false
        this.atConference = false
        this.notAvailable = true
        this.markedByPartTimeUser = false
      }
      // clear half absence attrs
      this.halfNotAvailable = false
      this.halfNotAvailableStartTime = ''
      this.halfNotAvailableEndTime = ''
      this.markedByPartTimeUser = false
    }
  },
  emits: [
    'active-tab-change',
    'remove-selection',
    'apply-to-selection',
    'apply-holiday-to-selection',
    'apply-not-available-to-selection'
  ],
  setup(props, { emit }) {
    const comment = ref(props.selectedDay.comment || '')
    const halfNotAvailable = ref(props.selectedDay.halfNotAvailable || false)
    const markedByPartTimeUser = ref(
      props.selectedDay.markedByPartTimeUser || false
    )
    const atConference = ref(props.selectedDay.atConference || false)
    const unpaid = ref(props.selectedDay.unpaid || false)
    const occasionalAbsence = ref(props.selectedDay.occasionalAbsence || false)
    const notAvailable = ref(props.selectedDay.notAvailable || false)
    notAvailable.value = !unpaid.value && !atConference.value
    const halfNotAvailableStartTime = ref(
      props.selectedDay.halfNotAvailableStartTime || ''
    )
    const halfNotAvailableEndTime = ref(
      props.selectedDay.halfNotAvailableEndTime || ''
    )

    watch(props.selectedDay, () => {
      comment.value = props.selectedDay.comment || ''
      halfNotAvailable.value = props.selectedDay.halfNotAvailable || false
      markedByPartTimeUser.value =
        props.selectedDay.markedByPartTimeUser || false
      atConference.value = props.selectedDay.atConference || false
      unpaid.value = props.selectedDay.unpaid || false
      occasionalAbsence.value = props.selectedDay.occasionalAbsence || false
      halfNotAvailableStartTime.value =
        props.selectedDay.halfNotAvailableStartTime || ''
      halfNotAvailableEndTime.value =
        props.selectedDay.halfNotAvailableEndTime || ''
      notAvailable.value = !unpaid.value && !atConference.value
    })

    return {
      MyWorkTab,
      emit,
      comment,
      halfNotAvailable,
      atConference,
      unpaid,
      occasionalAbsence,
      notAvailable,
      halfNotAvailableStartTime,
      halfNotAvailableEndTime,
      markedByPartTimeUser
    }
  }
})
