
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from 'vue'
import { CreateDayDto, TimelineUserDto } from '@/../service/__generated-api'
import { debounce } from 'ts-debounce'
import UserInTable from '@/components/user-in-table.vue'
import CommentTeleport from '@/components/calendars/comment-teleport.vue'
import {
  dayNotFilled,
  anyUnavailabilityWasMarked,
  shouldDisplayQuestionMark
} from '@/utils'

export default defineComponent({
  components: { UserInTable, CommentTeleport },
  props: {
    user: {
      type: Object as PropType<TimelineUserDto>,
      required: true
    },
    hours: {
      type: Array as PropType<number[]>,
      required: true
    }
  },
  methods: {
    dayNotFilled,
    anyUnavailabilityWasMarked,
    shouldDisplayQuestionMark,
    halfDayAbsenceMsg(day: CreateDayDto): string {
      return `\n${day.halfNotAvailableStartTime} - ${day.halfNotAvailableEndTime} + half-day absence`
    }
  },
  setup(props) {
    const timelineEl = ref<HTMLTableRowElement>()
    const periodsToRender = ref<
      { left: string; width: string; title: string; remote: boolean }[]
    >([])

    const stringArrayPeriods = props.user.days[0]?.periods?.length // User not available
      ? props.user.days[0].periods.split(',')
      : []

    const periods = stringArrayPeriods.map(period => {
      const [startTime, endTime] = period.split('-')
      const [startHour, startMinutes] = startTime.split(':').map(Number)
      const [endHour, endMinutes] = endTime.split(':').map(Number)

      const start = startHour + startMinutes / 60
      const end = endHour + endMinutes / 60

      return { start, end, period }
    })

    function renderPeriods() {
      if (!periods) return // KAKUTS

      const hourWidth =
        (timelineEl.value?.clientWidth ?? 0) / props.hours.length

      periodsToRender.value = periods.map(period => {
        const width = `${(period.end - period.start) * hourWidth + 1}px`
        const left = `${(period.start - props.hours[0]) * hourWidth}px`
        const title = period.period
        const remote = props.user.days[0].remote

        return { left, width, title, remote }
      })
    }

    onMounted(renderPeriods)

    const debouncedRenderPeriods = debounce(renderPeriods, 200)
    window.addEventListener('resize', debouncedRenderPeriods)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', debouncedRenderPeriods)
    })

    const isCommentHover = ref(false)
    const day = computed(() => props.user.days[0])

    return {
      day,
      isCommentHover,
      timelineEl,
      periodsToRender
    }
  }
})
