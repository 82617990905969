
import { defineComponent, PropType } from 'vue'
import { CreateDayDto, TimelineUserDto } from '@/../service/__generated-api'
import UserInTable from '@/components/user-in-table.vue'

export default defineComponent({
  components: { UserInTable },
  props: {
    user: {
      type: Object as PropType<TimelineUserDto>,
      required: true
    }
  },
  methods: {
    getFilteredAbsencesCount(
      criteria: Partial<Record<keyof CreateDayDto, boolean>>
    ): number {
      const isFullDayAbsence = (day: CreateDayDto) =>
        Object.entries(criteria).every(
          ([key, value]) => day[key as keyof CreateDayDto] === value
        ) && !day.halfNotAvailable

      const isHalfDayAbsence = (day: CreateDayDto) =>
        Object.entries(criteria).every(
          ([key, value]) => day[key as keyof CreateDayDto] === value
        ) && day.halfNotAvailable

      return (
        this.user.days.filter(isFullDayAbsence).length +
        this.user.days.filter(isHalfDayAbsence).length * 0.5
      )
    }
  },
  computed: {
    conferencesCount(): number {
      return this.getFilteredAbsencesCount({
        atConference: true,
        markedByPartTimeUser: false
      })
    },
    paidAcceptedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        notAvailable: true,
        notAvailableAccepted: true,
        markedByPartTimeUser: false
      })
    },
    paidPlannedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        notAvailable: true,
        notAvailableAccepted: false,
        markedByPartTimeUser: false
      })
    },
    unpaidAcceptedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        unpaid: true,
        notAvailableAccepted: true,
        markedByPartTimeUser: false
      })
    },
    unpaidPlannedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        unpaid: true,
        notAvailableAccepted: false,
        markedByPartTimeUser: false
      })
    },
    occasionalAcceptedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        occasionalAbsence: true,
        notAvailableAccepted: true,
        markedByPartTimeUser: false
      })
    },
    occasionalPlannedAbsencesCount(): number {
      return this.getFilteredAbsencesCount({
        occasionalAbsence: true,
        notAvailableAccepted: false,
        markedByPartTimeUser: false
      })
    },
    allAbsencesCount(): number {
      const isOverallAbsence = (day: CreateDayDto) =>
        !day.halfNotAvailable && !day.markedByPartTimeUser
      const isOverallHalfAbsence = (day: CreateDayDto) => day.halfNotAvailable

      return (
        this.user.days.filter(isOverallAbsence).length +
        this.user.days.filter(isOverallHalfAbsence).length * 0.5
      )
    }
  },
  setup(props) {
    const dayAbsenceMessage = (day: CreateDayDto) => {
      if (day.notAvailableAccepted && day.halfNotAvailable) {
        return 'Accepted half-day'
      }
      if (!day.notAvailableAccepted && day.halfNotAvailable) {
        return 'Not accepted half-day'
      }
      if (day.notAvailableAccepted) {
        return 'Accepted'
      } else if (day.halfNotAvailable && day.unpaid) {
        return 'Unpaid half-day'
      } else if (day.unpaid) {
        return 'Unpaid'
      }
      return 'Not accepted'
    }
    const courseMessage = (day: CreateDayDto) => {
      const msg = 'On course/conference'
      return day.halfNotAvailable ? `${msg} half-day` : msg
    }

    const formatAbsences = (
      filterFn: (day: CreateDayDto) => boolean | undefined,
      messageFn: (day: CreateDayDto) => string
    ) =>
      props.user.days
        .filter(day =>
          filterFn({
            ...day,
            markedByPartTimeUser: day.markedByPartTimeUser ?? false
          })
        )
        .map(
          (day, index) =>
            `${index + 1}. ${day.formattedDate} - ${messageFn(day)}`
        )
        .join('\n')

    const courses = formatAbsences(day => day.atConference, courseMessage)
    const paidAcceptedAbsences = formatAbsences(
      day =>
        day.notAvailable &&
        day.notAvailableAccepted &&
        !day.markedByPartTimeUser,
      dayAbsenceMessage
    )
    const paidPlannedAbsences = formatAbsences(
      day =>
        day.notAvailable &&
        !day.notAvailableAccepted &&
        !day.markedByPartTimeUser,
      dayAbsenceMessage
    )
    const unpaidAcceptedAbsences = formatAbsences(
      day =>
        day.unpaid && day.notAvailableAccepted && !day.markedByPartTimeUser,
      dayAbsenceMessage
    )
    const unpaidPlannedAbsences = formatAbsences(
      day =>
        day.unpaid && !day.notAvailableAccepted && !day.markedByPartTimeUser,
      dayAbsenceMessage
    )
    const occasionalAcceptedAbsences = formatAbsences(
      day =>
        day.occasionalAbsence &&
        day.notAvailableAccepted &&
        !day.markedByPartTimeUser,
      dayAbsenceMessage
    )
    const occasionalPlannedAbsences = formatAbsences(
      day =>
        day.occasionalAbsence &&
        !day.notAvailableAccepted &&
        !day.markedByPartTimeUser,
      dayAbsenceMessage
    )

    return {
      courses,
      paidAcceptedAbsences,
      paidPlannedAbsences,
      unpaidAcceptedAbsences,
      unpaidPlannedAbsences,
      occasionalAcceptedAbsences,
      occasionalPlannedAbsences
    }
  }
})
