import {
  addDays,
  nextMonday,
  format as formatFnsDate,
  parse as parseFnsDate
} from 'date-fns'
import { AxiosResponse } from 'axios'
import { CreateDayDto, DayDto } from '../service/__generated-api'

export const DAY_NAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

interface Period {
  from: string
  to: string
  id?: number
}

/**
 * @example ```ts
 * const periods = periodsStringify([
 *  { from: '08:00', to: '14:00' },
 *  { from: '15:00', to: '17:00' },
 * ])
 *
 * console.log(periods) // 08:00-14:00,15:00-17:00
 * ```
 */
export function periodsStringify(periods: Period[]): string {
  return periods.map(period => `${period.from}-${period.to}`).join(',')
}

/**
 * @example ```ts
 * const periods = periodsParse('08:00-14:00,15:00-17:00')
 *
 * console.log(periods)
 * // [
 * //   { from: '08:00', to: '14:00' },
 * //   { from: '15:00', to: '17:00' }
 * // ]
 * ```
 */
export function periodsParse(periods: string): Period[] {
  return periods.split(',').map(period => {
    const [from, to] = period.split('-')
    return { from, to }
  })
}

export function getNextDays(date: Date, daysNumber = 7): Date[] {
  const days: Date[] = []
  let nextDay = date

  for (let i = 0; i < daysNumber; i++) {
    days.push(new Date(nextDay))
    nextDay = addDays(nextDay, 1)
  }

  return days
}

export function getMondayFromDate(date = new Date()): Date {
  return nextMonday(addDays(date, -7))
}

export function formatDate(date = new Date()): string {
  return formatFnsDate(date, 'yyyy-MM-dd')
}

export function parseDate(date: string, format = 'yyyy-MM-dd'): Date {
  return parseFnsDate(date, format, new Date())
}

export function dayNotFilled(day: DayDto): boolean {
  return (
    day.businessDay &&
    !anyUnavailabilityWasMarked(day) &&
    day.periods.length === 0
  )
}

export function anyUnavailabilityWasMarked(day: CreateDayDto): boolean {
  return !!(
    day.notAvailable ||
    day.unpaid ||
    day.occasionalAbsence ||
    day.atConference ||
    day.halfNotAvailable
  )
}

export type notAvailableArgs = {
  comment: string
  halfNotAvailable: boolean
  atConference: boolean
  unpaid: boolean
  occasionalAbsence: boolean
  notAvailable: boolean
  halfNotAvailableStartTime: string
  halfNotAvailableEndTime: string
  markedByPartTimeUser: boolean
}

export function downloadXlsxResponse(response: AxiosResponse): void {
  const successStatusCodes: number[] = [201, 200]
  if (successStatusCodes.includes(response.status)) {
    const disposition: string = response.headers['content-disposition']
    const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/
    const match = disposition.match(filenameRegex)
    if (!match) {
      return
    }
    const filename = match[1]
    const url: string = URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      })
    )
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
}

export function nonNullable<T>(obj: T): obj is NonNullable<T> {
  return !!obj
}

export function normalizeArray<T>(
  element?: T | Array<T>
): Array<NonNullable<T>> {
  return nonNullable(element)
    ? Array.isArray(element)
      ? element.filter(nonNullable)
      : [element]
    : []
}

export function shouldDisplayQuestionMark(day?: DayDto): boolean {
  return (
    ((day?.notAvailable || day?.occasionalAbsence || day?.unpaid) &&
      !day?.notAvailableAccepted &&
      !day?.markedByPartTimeUser) ??
    false
  )
}
