import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { title: "Total year's absences" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_in_table = _resolveComponent("user-in-table")

  return (_openBlock(), _createBlock("tr", _hoisted_1, [
    _createVNode(_component_user_in_table, { user: _ctx.user }, null, 8, ["user"]),
    _createVNode("td", { title: _ctx.paidAcceptedAbsences }, _toDisplayString(_ctx.paidAcceptedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.paidPlannedAbsences }, _toDisplayString(_ctx.paidPlannedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.unpaidAcceptedAbsences }, _toDisplayString(_ctx.unpaidAcceptedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.unpaidPlannedAbsences }, _toDisplayString(_ctx.unpaidPlannedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.occasionalAcceptedAbsences }, _toDisplayString(_ctx.occasionalAcceptedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.occasionalPlannedAbsences }, _toDisplayString(_ctx.occasionalPlannedAbsencesCount), 9, ["title"]),
    _createVNode("td", { title: _ctx.courses }, _toDisplayString(_ctx.conferencesCount), 9, ["title"]),
    _createVNode("td", _hoisted_2, _toDisplayString(_ctx.allAbsencesCount), 1)
  ]))
}